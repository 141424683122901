import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'

const NotFoundPage = () => (
  <Layout pageTitle="404">
    <section>
      <Container>
        <h1>404</h1>
        <p>Die gesuchte Seite wurde nicht gefunden. <Link to='/'>Zur Startseite</Link></p>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
